import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import php from "./img/icons/php.png";
import css from "./img/icons/css-3.png";
import html from "./img/icons/html.png";
import js from "./img/icons/js.png";
import node from "./img/icons/node-js.png";
import react from "./img/icons/react.png";
import laravel from "./img/icons/laravel.png";

const someData = [
  {
    image: php,
    class: "one",
  },
  {
    image: html,
    class: "two",
  },
  {
    image: css,
    class: "three",
  },
  {
    image: js,
    class: "four",
  },
  {
    image: node,
    class: "five",
  },
  {
    image: react,
    class: "six",
  },
  {
    image: laravel,
    class: "seven",
  },
];

export default function Avatar() {
  const temp = someData.map((o) => (
    <div className="avatar">
      <img className={o.class} src={o.image} />
    </div>
  ));
  return (
    <div className="flex justify-between px-2 md:px-5">{temp}</div>
  );
}
