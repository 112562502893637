import React from "react";
import FadeIn from "react-fade-in";

export default function Loader() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <FadeIn>
        <p className="-mt-10 text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold uppercase tracking-wide	">
          <code
            class="typeWriter home"
            data-checkVisible="true"
            data-speed="3"
            data-text='["< SUNDER DEV /> "]'
          ></code>
        </p>
      </FadeIn>
    </div>
  );
}
