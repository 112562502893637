import React from "react";
import SocialIcon from "./SocialIcon";

export default function AllSocialIcons() {
  return (
    <div className="flex items-center justify-center">
      {/* <SocialIcon name="bi bi-phone" link="tel:+2250142144740" /> */}
      {/* <SocialIcon
        name="bi bi-facebook"
        link="https://www.facebook.com/sunderdevv"
      /> */}
      <SocialIcon name="bi bi-envelope" link="mailto:ahmedsanoko1@gmail.com" />
      <SocialIcon
        name="bi bi-instagram"
        link="https://www.instagram.com/sunder__dev/"
      />
      <SocialIcon name="bi bi-whatsapp" link="https://wa.me/+33753624765" />
    </div>
  );
}
