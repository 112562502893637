import React from "react";
import AllSocialIcons from "./AllSocialIcons";
import Logo from './img/logo1.jpg'

export default function Header() {
  return (
    <header className="flex items-center justify-between px-3 py-2">
      <img src={Logo} className="logo"/>
      <AllSocialIcons />
    </header>
  );
}
