import React from "react";
import Card from "./Card";
import Ms from "./img/app/msApp.png";
import Tis from "./img/app/tisApp.png";
import nappylocks from "./img/app/nappylocks.png";

const someData = [
  {
    name: "M-Services",
    image: Ms,
    desc: "Application permettant de commander des professionnels de métiers pour nos tâches aux quotidien",
    link: "https://bit.ly/3CkfvwE",
    linkTwo: "https://bit.ly/3f3YVHL",
  },
  {
    name: "Total Industries Service",
    image: Tis,
    desc: "Application fournissant des services de dépannage et de mise en vente d'équipements de pointes de dernière génération",
    link: "https://bit.ly/3dqsiU6",
    linkTwo: "https://bit.ly/3S0vlRX",
  },

  {
    name: "Nappylocks",
    image: nappylocks,
    desc: "Shopping de dreads et produits pour peaux",
    link: "https://play.google.com/store/apps/details?id=com.sunder.nappylocks",
    linkTwo: "https://apps.apple.com/us/app/nappylocks/id1661966757",
  },
];

export default function MobileProjects() {
  const temp = someData.map((o) => (
    <Card
      isApp
      title={o.name}
      image={o.image}
      desc={o.desc + "."}
      link={o.link}
      linkTwo={o.linkTwo}
    />
  ));

  return (
    <div className="bg-gray-100">
      <div className="pt-10 p-5 max-w-2xl md:max-w-3xl lg:max-w-5xl mx-auto">
        <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold uppercase">
          Applications Mobile
        </h2>
        <div className="mt-1 md:mt-3 mb-5 md:mb-10 h-1 w-[15vw] md:w-[10vw] bg-gray-300 shadow rounded-full"></div>
        <div className="flex flex-wrap justify-between items-center ">
          {temp}
        </div>
      </div>
    </div>
  );
}
