import React from "react";
import Card from "./Card";
import Blog from "./img/blog_img.png";
import Data from "./img/data_img.png";
import Mservices from "./img/mservices_img.png";
import THC from "./img/th_img.png";
import Portfoliov1 from "./img/portfoliov1.png";
import Portfoliov2 from "./img/portfoliov2.png";
import PortfolioV3 from "./img/portfolioV3.png";
import Meteo from "./img/meteo_img.png";
import Dobet from "./img/dobet.png";
import Tis from "./img/tis.png";
import calculator from "./img/calculator.png";
import todo from "./img/todo.png";
import admin from "./img/admin.png";
import netflix from "./img/netflix.png";

const someData = [
  {
    name: "Dashboard administrateur",
    image: admin,
    desc: "Un exemple de tableau de bord que je fournis généralement à mes clients pour piloter leurs sites web (gestion d'utilisateurs, de commandes, etc ...)",
    link: "https://admin-demo.codewithsunder.dev/",
  },
  {
    name: "Clone de Netflix",
    image: netflix,
    desc: "Simple clone de Netflix en REACT",
    link: "https://netflix-demo.codewithsunder.dev/",
  },
  {
    name: "Calculatrice",
    image: calculator,
    desc: "Simple calculatrice fonctionnelle en REACT",
    link: "https://calculator-demo.codewithsunder.dev/",
  },
  {
    name: "Application Todo List",
    image: todo,
    desc: "Simple Todo liste fonctionnelle en REACT, vous pouvez même l'utiliser au quotidien 😊",
    link: "https://todo-demo.codewithsunder.dev/",
  },
  {
    name: "Portfolio v1",
    image: Portfoliov1,
    desc: "1ère version de mon portfolio",
    link: "https://v1.codewithsunder.dev",
  },
  {
    name: "Portfolio v2",
    image: Portfoliov2,
    desc: "2ème version de mon portfolio",
    link: "https://v2.codewithsunder.dev",
  },
  {
    name: "Portfolio v3",
    image: PortfolioV3,
    desc: "3ème version de mon portfolio",
    link: "https://v3.codewithsunder.dev",
  },
  {
    name: "M-Services",
    image: Mservices,
    desc: "Entreprise fournissant des professionnels de métiers aux populations",
    link: "https://mservices.midelsgroup.com/",
  },
  {
    name: "TH Construction",
    image: THC,
    desc: "Entreprise de construction immobilière basée à Abidjan CI",
    link: "https://th-construction.com",
  },
  {
    name: "Dobet Gnahore",
    image: Dobet,
    desc: "Auteur - Compositeur, chanteuse et danseuse de renomée internationale",
    link: "https://dobetgnahore.com",
  },
  {
    name: "Total Industries Service",
    image: Tis,
    desc: "Entreprise fournissant des services de dépannage et de mise en vente d'équipements de pointes",
    link: "https://tis.ci",
  }
];

export default function Projects() {
  const temp = someData.map((o) => (
    <Card title={o.name} image={o.image} desc={o.desc + "."} link={o.link} />
  ));

  return (
    <div className="bg-gray-100">
      <div className="pt-20 p-5 max-w-2xl md:max-w-3xl lg:max-w-5xl mx-auto">
        <h2 className="text-2xl md:text-3xl xl:text-4xl font-bold uppercase">
          Sites Web
        </h2>
        <div className="mt-1 md:mt-3 mb-5 md:mb-10 h-1 w-[15vw] md:w-[10vw] bg-gray-300 shadow rounded-full"></div>
        <div className="flex flex-wrap justify-between items-center ">
          {temp}
        </div>
      </div>
    </div>
  );
}
