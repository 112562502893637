import React, { useState } from "react";
import playStore from "./img/playStore.png";
import appStore from "./img/appStore.png";

export default function Card(props) {
  const [toggler, setToggler] = useState(false);

  return (
    <a href={props.link} target="_blank"
      className="card shadow-xl bg-black"
      style={{
        borderRadius: 5,
        marginBottom: 25,
      }}
    >
      <img className="projectImg" src={props.image} />
      <div className="flex flex-col">
        <div className="p-3">
          <div className="flex justify-between items-center">
            <code className="text-xs md:text-sm lg:text-md xl:text-lg">
              {props.title}
            </code>
            {!props.isApp && (
              <a href={props.link} target="_blank">
                <i class="bi bi-arrow-right projectIcon"></i>
              </a>
            )}
          </div>
          {props.isApp && <code>Disponible sur IOS & Android</code>}
          <p className="mt-3 text-xs lg:text-sm xl:text-md text-white w-full">
            {props.desc}
          </p>
          {props.isApp && (
            <div className="mt-3 flex items-center">
              <div>
                <img className="storeImg" src={playStore} />
              </div>
              <a href={props.linkTwo} target="_blank">
                <img className="storeImg" src={appStore} />
              </a>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}
