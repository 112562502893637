import React, { useEffect, useState } from "react";
import "./App.scss";
import Projects from "./Projects";
import Avatar from "./Avatar";
import MobileProjects from "./MobileProjects";
import Tarifs from "./Tarifs";
import Loader from "./Loader";
import FadeIn from "react-fade-in";
import Header from "./Header";

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  function goToHome(e) {
    window.location.href = "#";
    e.preventDefault();
    e.stopPropagation();
  }

  function goToProjects(e) {
    window.location.href = "#projects";
    e.preventDefault();
    e.stopPropagation();
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="relative pb-20">
      <section className="firstSection relative h-screen">
      <Header />
        <div className="px-3 text-white flex flex-col items-center justify-center text-center h-full">
          <FadeIn transitionDuration={3000}>
            <div className="categoryWrapper">
              <code className="home mx-2 text-3xl md:text:4xl lg:text-5xl xl:text-6xl font-bold">
                {" < "}SUNDER DEV{" /> "}
              </code>
              <p className="mt-5 text-lg uppercase tracking-wide	">
                <span
                  className="typeWriter"
                  data-checkVisible="true"
                  data-speed="4"
                  data-text='["Développeur d&#39;applications mobile,", "de sites web pour particuliers et entreprises."]'
                ></span>
              </p>

              <button onClick={goToProjects}>
                <span>
                  <span>
                    <span data-attr-span="Voir projets">Voir projets</span>
                  </span>
                </span>
              </button>
            </div>
          </FadeIn>
        </div>
        <FadeIn delay={1000} transitionDuration={3000}>
          <Avatar />
        </FadeIn>
      </section>
      <section id="projects">
        <Projects />
        <MobileProjects />
        {/* <Tarifs /> */}
      </section>
      <button
        className="bottomButton rounded shadow flex items-center justify-center px-2 md:px-4 py-2 bg-gray-800 hover:bg-blue-900 duration-200 ease-in-out text-white text-md md:text-xl mx-auto"
        onClick={goToHome}
      >
        <span>Me contacter</span>
        <i className="ml-3 bi bi-arrow-up"></i>
      </button>
    </div>
  );
}
